import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import {__} from '../translation.js'
const SwalModal = Swal.mixin({
  position: 'center'
})
const SwalDialog = Swal.mixin({
  toast: false,
  position: 'center',
  confirmButtonText: __('Confirm'),
  cancelButtonText: __('Cancel'),
  confirmButtonColor: '#007755',
  cancelButtonColor: '#555555',
  showCancelButton: true
})

const SwalNotification = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const Dialog = {
  confirm: function(title, text, config) {
    return SwalDialog.fire({
      icon: 'warning',
      title: title,
      text: text,
      ...config
    })
  }
}
const Notification = {
  success: function(title, text, config) {
    return SwalNotification.fire({
      title: title,
      text: text,
      icon: 'success',
      ...config
    })
  },
  error: function(title, text, config) {
    return SwalNotification.fire({
      title: title,
      text: text,
      icon: 'error',
      ...config
    })
  },
  warning: function(title, text, config) {
    return SwalNotification.fire({
      title: title,
      text: text,
      icon: 'warning',
      ...config
    })
  },
  info: function(title, text, config) {
    return SwalNotification.fire({
      title: title,
      text: text,
      icon: 'info',
      ...config
    })
  },
  question: function(title, text, config) {
    return SwalNotification.fire({
      title: title,
      text: text,
      icon: 'question',
      ...config
    })
  }
}
export { Dialog, Notification, SwalModal, SwalDialog, SwalNotification }
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="89b117be-1326-51f0-9c07-aa71c022bcf7")}catch(e){}}();
//# debugId=89b117be-1326-51f0-9c07-aa71c022bcf7
